import type {
  HybrisCustomerAddressBook,
  HybrisAddress,
  Title,
  AddressForm,
  Country,
  Region,
  RegionsMap,
  CustomerAddressDetailsData,
} from '@hybris/types';

const addressGetters = {
  getMappedAddresses: (data: HybrisCustomerAddressBook): HybrisAddress[] => {
    // Combine both additional shipping addresses and the single billing address into one array
    if (data) {
      return [
        ...(data.additionalAddresses || []),
        ...(data.billingAddress ? [data.billingAddress] : [])
      ];
    }

    return [];
  },
  getAddresses: (addresses: HybrisAddress[], criteria?: Record<string, any>): HybrisAddress[] => {
    if (!criteria) {
      return addresses;
    }

    return addresses?.filter(address =>
      Object.entries(criteria).every(([key, value]) => address[key as keyof HybrisAddress] === value)
    ) || [];
  },
  getTotal: (addresses: HybrisAddress[]): number => addresses?.length || 0,
  getPostCode: (address: HybrisAddress): string => address?.postalCode || '',
  getStreetName: (address: HybrisAddress): string => address?.line1 || '',
  getCity: (address: HybrisAddress): string => address?.town || '',
  getFirstName: (address: HybrisAddress): string => address?.firstName || '',
  getLastName: (address: HybrisAddress): string => address?.lastName || '',
  getCountry: (address: HybrisAddress): Country => address?.country || null,
  getPhone: (address: HybrisAddress): string => address?.phone || '',
  getEmail: (address: HybrisAddress): string => address?.email || '',
  getApartmentNumber: (address: HybrisAddress): string => address?.line2 || '',
  getProvince: (address: HybrisAddress): string => address?.country?.isocode || '',
  getId: (address: HybrisAddress): string => address?.id || '',
  isDefault: (address: HybrisAddress | AddressForm): boolean =>
    (address?.isDefaultShippingAddress && address?.isDefaultBillingAddress) || false,
  isDefaultShipping: (address: HybrisAddress | AddressForm): boolean => address?.isDefaultShippingAddress || false,
  isDefaultBilling: (address: HybrisAddress | AddressForm): boolean => address?.isDefaultBillingAddress || false,
  getCountryName: (address: HybrisAddress): string => address?.country?.name || '',
  getTitles: (addressesDetails: CustomerAddressDetailsData): Title[] => {
    return addressesDetails?.titles?.filter((title: Title) => title.hasOwnProperty('name')) || [];
  },
  getCountriesList: (addressDetails: CustomerAddressDetailsData): Country[] => {
    return addressDetails?.countries?.sort((a: Country, b: Country) => a.name.localeCompare(b.name)) || [];
  },
  getCountryRegions: (country: string, regions: RegionsMap): Region[] => {
    if (!country || !regions) return [];

    return regions[country] || [];
  }
};

export default addressGetters;
